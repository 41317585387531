<script setup>
import { computed, defineProps, getCurrentInstance, ref } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import i18n from "@/core/plugins/vue-i18n";
import {
  exportStartedStates,
  importStartedStates
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import { useFeatureReporting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureReporting";
import ProcessIteration from "@/components/Workflows/processIteration";

const { selectedFeature } = useFeatures();
const app = getCurrentInstance();
const {
  getStateProcessHeader,
  isDateMoreThan14DaysAgo,
  convertTimestampToDate
} = useFeatureReporting();

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
});

const badgeType = computed(() => {
  const {
    data: {
      item: { state }
    }
  } = props;

  if (
    (state === "imported" && selectedFeature.value !== "tracking") ||
    state === "stateImported"
  ) {
    return "success";
  }

  if (state.toLowerCase().includes("partially")) {
    return "warning";
  }

  if (errorState.value) {
    return "error";
  }

  return "default";
});

const errorState = computed(() => {
  const {
    data: {
      item: { error: errorState }
    }
  } = props;

  return errorState;
});

const elementId = computed(() => "state-" + props.data?.item?.orderId);

const item = computed(() => {
  const { data } = props;
  const state = data?.item?.state ?? "";
  const entity = data?.field?.entity ?? "default";

  let translateString = JSON.parse(JSON.stringify(state));

  if (errorState.value) {
    return (
      data?.item?.state ?? i18n.t("salesChannelManagementApp.reporting.error")
    );
  }

  if (selectedFeature.value === "tracking" && translateString === "imported") {
    translateString = "noStateUpdate";
  }

  return i18n.t(
    "salesChannelManagementApp.reporting.states." +
      entity +
      "." +
      translateString
  );
});

const error = computed(() => {
  const {
    data: {
      item: { state, lastIteration },
      field: { stateHideErrorBadge }
    }
  } = props;

  if (stateHideErrorBadge) return false;

  const date = convertTimestampToDate(lastIteration);

  return (
    !![...importStartedStates, ...exportStartedStates].includes(state ?? "") &&
    !isDateMoreThan14DaysAgo(date)
  );
});

const errorMessage = ref("default");

async function onErrorHover() {
  const { data } = props;

  if (errorMessage.value !== "default" || !data?.item?.processIterationId) {
    app.proxy.$root.$emit("bv::show::popover", elementId.value);
    return;
  }

  document.body.style.cursor = "wait";

  let optionalHeaders = getStateProcessHeader(data.item);

  try {
    const response = await ProcessIteration.get(
      data.item.processIterationId,
      ["status", "content_identifier"],
      optionalHeaders
    );
    const processIteration = response.data ?? {};
    const responseStatus = processIteration?.status;
    const responseContentIdentifier = processIteration?.content_identifier;

    if (responseStatus !== "process.error") {
      errorMessage.value = "";
    } else {
      errorMessage.value = responseContentIdentifier;
      app.proxy.$root.$emit("bv::show::popover", elementId.value);
    }
  } catch (error) {
    Error(error);
  } finally {
    document.body.style.cursor = "default";
  }
}

async function onErrorLeave() {
  app.proxy.$root.$emit("bv::hide::popover", elementId.value);
}
</script>

<template>
  <span class="state-badge" :class="'state-badge-' + badgeType">
    {{ item }}

    <span
      v-if="error"
      v-b-popover.manual.bottom="{ content: errorMessage, id: elementId }"
      class="state-error-badge"
      @mouseenter="onErrorHover"
      @mouseleave="onErrorLeave"
    >
      <i class="fal fa-exclamation"></i>
    </span>
  </span>
</template>

<style lang="scss" scoped>
.state-badge {
  padding: 6px 12px;
  border-radius: 6px;
  position: relative;
  white-space: nowrap;

  @each $type, $color in $connect-status-types {
    &-#{$type} {
      background-color: rgba($color, 0.3);
      color: darken($color, 30%);
    }
  }
}

.state-error-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: $color-connect-error;
  display: flex;
  place-items: center;
  place-content: center;

  > i {
    color: #fff;
    font-size: 14px;
  }
}
</style>
