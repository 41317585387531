import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import DataSets from "@/components/DataSets/dataSets";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { stateMachineStates } from "@/components/ExternalApps/SalesChannelManagementApp/utility/stateMachineStates";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { getStagesPayload } from "@/components/ExternalApps/SalesChannelManagementApp/utility/reporting";
import {
  completeStartedStates,
  importStartedStates
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import { useUtility } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useUtility";
import ProcessIteration from "@/components/Workflows/processIteration";

const { selectedFeature, features } = useFeatures();
const { xentralProjectHeader } = useUtility();
const { selectedIntegration } = useIntegration();

const listDataSetId = computed(() => getListDataSetId(selectedFeature.value));
const detailDataSetId = computed(() =>
  getDetailDataSetId(selectedFeature.value)
);
const reportingEnabled = computed(() =>
  isReportingEnabled(selectedFeature.value)
);
const store = useStore();
const fields = {
  salesOrder: [
    {
      key: "orderNumber",
      label: i18n.t("salesChannelManagementApp.reporting.orderNumber"),
      thClass: "pl-5",
      tdClass: "pl-5",
      sortable: true,
      type: "stateMachineOrderNumber"
    },
    {
      key: "orderId",
      label: i18n.t("salesChannelManagementApp.reporting.orderId"),
      sortable: true
    },
    {
      key: "state",
      label: i18n.t("salesChannelManagementApp.reporting.state"),
      type: "importState",
      sortable: true,
      entity: "salesOrder"
    },
    {
      key: "orderDate",
      label: i18n.t("salesChannelManagementApp.reporting.orderDate"),
      sortable: true,
      type: "statusDatetime",
      onlyDate: true
    },
    {
      key: "lastIteration",
      label: i18n.t("salesChannelManagementApp.reporting.lastIteration"),
      sortable: true
    }
  ],
  tracking: [
    {
      key: "orderNumber",
      label: i18n.t("salesChannelManagementApp.reporting.orderNumber"),
      thClass: "pl-5",
      tdClass: "pl-5",
      sortable: true,
      type: "stateMachineOrderNumber"
    },
    {
      key: "orderId",
      label: i18n.t("salesChannelManagementApp.reporting.orderId"),
      sortable: true
    },
    {
      key: "state",
      label: i18n.t("salesChannelManagementApp.reporting.state"),
      type: "importState",
      sortable: true,
      entity: "salesOrder"
    },
    {
      key: "trackingAdded",
      label: i18n.t("salesChannelManagementApp.reporting.trackingAdded"),
      sortable: true,
      type: "badge",
      snippets: {
        true: i18n.t("salesChannelManagementApp.reporting.trackingExported")
      }
    },
    {
      key: "orderDate",
      label: i18n.t("salesChannelManagementApp.reporting.orderDate"),
      sortable: true,
      type: "statusDatetime",
      onlyDate: true
    },
    {
      key: "lastIteration",
      label: i18n.t("salesChannelManagementApp.reporting.lastIteration"),
      sortable: true
    }
  ]
};

const actions = ref([
  {
    key: "requeue",
    icon: "fal fa-reply",
    emit: "requeue-job",
    tooltip: i18n.t("salesChannelManagementApp.reporting.requeueJob")
  },
  {
    key: "detail",
    icon: "fal fa-magnifying-glass",
    emit: "show-detail",
    tooltip: i18n.t("salesChannelManagementApp.reporting.showDetail")
  }
]);

const batchActions = computed(() => {
  if (store.getters.testBatchWorkflowId(selectedFeature.value)) {
    return [
      {
        key: "requeue",
        icon: "fal fa-share-all fa-flip-horizontal",
        emit: "requeue-jobs",
        tooltip: i18n.t("salesChannelManagementApp.reporting.requeueJobs")
      }
    ];
  }
  return [];
});

const showDetailModal = ref(false);
const currentDetailItem = ref({});
const reportingDetailData = ref([]);
const reportingDetailMeta = ref({});
const reportingDetailFields = ref([
  {
    key: "source",
    label: i18n.t("salesChannelManagementApp.reporting.source"),
    thClass: "pl-5",
    tdClass: "pl-5"
  },
  {
    key: "destination",
    label: i18n.t("salesChannelManagementApp.reporting.destination")
  },
  {
    key: "state",
    label: i18n.t("salesChannelManagementApp.reporting.state"),
    type: "importState",
    stateHideErrorBadge: true,
    entity: "salesOrder"
  },
  {
    key: "lastIteration",
    label: i18n.t("salesChannelManagementApp.reporting.lastIteration"),
    stickyColumn: true
  }
]);

const defaultStates = computed(() =>
  stateMachineStates[selectedFeature.value].map(entry => entry.value)
);

function getStateProcessHeader(item) {
  if (importStartedStates.includes(item.state)) {
    return xentralProjectHeader.value;
  }

  return {};
}

function isReportingEnabled(feature) {
  return !!(
    (features.value.includes(feature) &&
      getListDataSetId(feature) &&
      getDetailDataSetId(feature)) ||
    (feature === "category" &&
      store.getters?.testWorkflowId("category") &&
      ["woocommerce", "shopware6"].includes(store.getters.appType) &&
      selectedIntegration.value?.features?.value?.category)
  );
}

function getListDataSetId(feature) {
  return store.getters.stateMachineDataSet(feature);
}

function getDetailDataSetId(feature) {
  return store.getters.stateMachineDataSet(feature, "detail");
}

export const useFeatureReporting = () => {
  const { selectedIntegration } = useIntegration();
  const paramPrefix = computed(() => "reporting-data-" + selectedFeature.value);
  const paramPrefixDetail = computed(
    () => "reporting-detail-" + selectedFeature.value
  );
  const search = ref("null");
  const quickFilterModel = ref({});
  const reportingData = ref([]);
  const meta = ref({});
  const quickFilterParameters = ref([]);
  const isFiltered = ref(false);
  const activeFilter = computed(() => {
    for (const value of Object.values(quickFilterModel.value)) {
      if (value) return true;
    }
    return false;
  });

  function executeDataSet() {
    addEventToLoadingQueue({
      key: "load-reporting-data",
      group: "load-reporting-data"
    });

    let payload = getStagesPayload(paramPrefix.value, defaultStates, {}, false);
    payload.parameters = [
      ...payload.parameters,
      {
        name: "channelSpecificName",
        type: "text",
        value:
          selectedIntegration?.value?.name
            ?.replace(store.getters.selectedApp.name, "")
            .trim() ?? ""
      },
      {
        name: "search",
        type: "text",
        value: search.value === "" ? "null" : search.value
      }
    ];

    for (const key in quickFilterModel.value) {
      if (String(quickFilterModel.value[key]) === "") continue;
      payload.parameters.push({
        name: key,
        type: "text",
        value: String(quickFilterModel.value[key])
      });
    }

    isFiltered.value = activeFilter.value || !!search.value;
    quickFilterParameters.value = payload.parameters;

    DataSets.execute(
      listDataSetId.value,
      payload,
      xentralProjectHeader.value
    ).then(response => {
      reportingData.value = response.data;
      meta.value = response.meta;

      removeEventFromLoadingQueue({ group: "load-reporting-data" });
    });
  }

  async function showDetail(event) {
    const { item } = event;

    if (item) {
      currentDetailItem.value = item;
    }

    addEventToLoadingQueue({
      key: "load-reporting-detail",
      progressBarKey: "load-reporting-detail",
      group: "load-reporting-detail"
    });

    showDetailModal.value = true;

    const condition = {
      type: "condition",
      field: "$relatedDataEntry",
      operator: "$eq",
      value: currentDetailItem?.value?.relatedDataEntry ?? "",
      valid: true
    };

    const payload = getStagesPayload(
      paramPrefixDetail.value,
      defaultStates,
      condition
    );

    const response = await DataSets.execute(detailDataSetId.value, payload);
    if (selectedFeature.value === "tracking") {
      const entryToChange = response.data.find(
        entry => entry.state === "imported"
      );

      if (entryToChange) {
        entryToChange.destination = store.getters.selectedApp.label;
      }
    }

    reportingDetailData.value = response.data;

    const lastState = reportingDetailData.value.at(0);
    const date = convertTimestampToDate(lastState?.lastIteration);
    const isMoreThan14DaysAgo = isDateMoreThan14DaysAgo(date);

    if (
      completeStartedStates.includes(lastState?.state) &&
      lastState?.processIterationId &&
      !isMoreThan14DaysAgo
    ) {
      let optionalHeaders = getStateProcessHeader(lastState);
      const response = await ProcessIteration.get(
        lastState.processIterationId,
        ["status", "content_identifier"],
        optionalHeaders
      );
      const processIteration = response.data ?? {};

      if (processIteration.status === "process.error") {
        let copy = JSON.parse(JSON.stringify(reportingDetailData.value.at(0)));
        reportingDetailData.value.unshift({
          ...copy,
          ...{
            id: "",
            state: processIteration?.content_identifier,
            error: true,
            comment: ""
          }
        });
      }
    }

    reportingDetailMeta.value = response.meta;
    removeEventFromLoadingQueue({
      key: "load-reporting-detail",
      progressBarKey: "load-reporting-detail",
      group: "load-reporting-detail"
    });
  }

  function isDateMoreThan14DaysAgo(date) {
    if (!date) return true;

    const currentDate = new Date();
    const date14DaysAgo = new Date();
    date14DaysAgo.setDate(currentDate.getDate() - 14);

    return date < date14DaysAgo;
  }

  function convertTimestampToDate(timestamp) {
    if (!timestamp) return undefined;

    const [datePart, timePart] = timestamp.split(" ");
    if (!datePart || !timePart) return undefined;

    const [day, month, year] = datePart.split(".").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (!day || !month || !year || !hours || !minutes || !seconds) {
      return undefined;
    }

    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  return {
    actions,
    batchActions,
    fields,
    meta,
    paramPrefix,
    paramPrefixDetail,
    quickFilterModel,
    reportingData,
    reportingDetailData,
    reportingDetailFields,
    reportingDetailMeta,
    reportingEnabled,
    search,
    showDetailModal,
    quickFilterParameters,
    isFiltered,
    convertTimestampToDate,
    executeDataSet,
    getStateProcessHeader,
    isDateMoreThan14DaysAgo,
    isReportingEnabled,
    showDetail
  };
};
